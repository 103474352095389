<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item three-line>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img :src="profile.imgAvatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-h6"
            v-text="profile.title"
          ></v-list-item-title>
          <v-list-item-subtitle
            class="text-h8"
            v-text="profile.role"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-h7"
            v-text="profile.subtitle"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <v-list expand nav>
        <template v-for="(item, i) in items">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
//import { mapActions } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [],
    itemsAdministrador: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/",
      },
      {
        title: "Administracion",
        group: "/admin",
        children: [
          {
            title: "Cargos",
            to: "cargos",
          },
          {
            title: "Categoria de Gastos",
            to: "categoria_gastos",
          },
          {
            title: "Combustibles",
            to: "combustibles",
          },
          {
            title: "Configuraciones",
            to: "configuraciones",
          },
          {
            title: "Departamentos",
            to: "departamentos",
          },
          {
            title: "Empresas",
            to: "empresas",
          },
          {
            title: "Estados",
            to: "estados",
          },
          {
            title: "Gastos",
            to: "gastos",
          },
          {
            title: "Marcas",
            to: "marcas",
          },
          {
            title: "Modelos",
            to: "modelos",
          },
          {
            title: "Repuestos",
            to: "repuestos",
          },
          {
            title: "Seguros",
            to: "seguros",
          },
          {
            title: "Servicios",
            to: "servicios",
          },
          {
            title: "Tipo de Alertas",
            to: "tipo_alertas",
          },
          {
            title: "Tipo de Documentos",
            to: "tipo_documentos",
          },
          {
            title: "Tipo de Gastos",
            to: "tipo_gastos",
          },
          {
            title: "Tipo de Licencias",
            to: "tipo_licencias",
          },
          {
            title: "Tipo de Trabajos",
            to: "tipo_trabajos",
          },
          {
            title: "Tipo de Vehiculos",
            to: "tipo_vehiculos",
          },
          {
            title: "Zonas",
            to: "zonas",
          },
        ],
      },
      {
        title: "Alertas",
        group: "/alertas",
        children: [
          {
            title: "Alertas",
            to: "alertas",
          },
          {
            title: "Alertas Choferes",
            to: "choferes",
          },
          {
            title: "Alertas Seguros",
            to: "seguros",
          },
          {
            title: "Alertas Vehiculos",
            to: "vehiculos",
          },
        ],
      },
      {
        title: "Choferes",
        group: "/choferes",
        children: [
          {
            title: "Choferes",
            to: "choferes",
          },
        ],
      },
      {
        title: "Cocheras",
        group: "/cocheras",
        children: [
          {
            title: "Playas",
            to: "playas",
          },
        ],
      },
      {
        title: "Conductores",
        group: "/conductores",
        children: [
          {
            title: "Conductores",
            to: "conductores",
          },
        ],
      },
      {
        title: "Vehiculos",
        group: "/vehiculos",
        children: [
          {
            title: "Vehiculos",
            to: "vehiculos",
          },
        ],
      },
      {
        title: "Pasajeros",
        group: "/pasajeros",
        children: [
          {
            title: "Pasajeros",
            to: "pasajeros",
          },
        ],
      },
    ],
    itemsSeguridad: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/dashboard",
      },
      {
        title: "Seguridad",
        icon: "",
        group: "/admin",
        children: [
          {
            title: "Usuarios",
            to: "usuarios",
          },
          {
            title: "Permisos",
            to: "permission",
          },
          {
            title: "Roles",
            to: "role",
          },
          {
            title: "Configuraciones",
            to: "configuraciones",
          },
          {
            title: "Auditoria",
            to: "auditoria",
          },
          {
            title: "Firewall",
            to: "firewall",
          },
        ],
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {},

    profile() {
      return {
        avatar: true,
        imgAvatar: "/img/user_matrice.png",
        /*imgAvatar:
			          "https://ui-avatars.com/api/?name=" + this.$store.state.user.role,*/
        //title: this.$store.state.user.name,
        title: this.$keycloak.tokenParsed.preferred_username,
        //subtitle: "MATRICE LOGIN",
        subtitle: this.$keycloak.tokenParsed.foo_tenants
          ? this.$keycloak.tokenParsed.foo_tenants[0].toUpperCase()
          : "FLOTA",
        //role: "Perfil:" + this.$store.state.user.role,
        role:
          this.$keycloak.tokenParsed.given_name +
          " " +
          this.$keycloak.tokenParsed.family_name,
      };
    },
  },
  created() {
    let data = {};
    this.setNavDrawer();
  },
  methods: {
    /*...mapActions({
			      signIn: "login",
			      signOut: "logout",
			    }),*/
    setNavDrawer() {
      //this.signIn();
      //console.log(this.$store.state.user);
      /*
			      if (this.$keycloak.hasResourceRole("administrador")) {
			        this.items = this.itemsAdministrador;
			      }
			      if (this.$keycloak.hasResourceRole("seguridad")) {
			        this.items = this.itemsSeguridad;
			      }
			      */
      if (this.is("administrador") || this.is("usuario")) {
        this.items = this.itemsAdministrador;
      }
      if (this.is("seguridad")) {
        this.items = this.itemsSeguridad;
      }

      return this.items.map(this.mapItem);
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
